/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 Magrita.glb 
*/

import React from 'react'
import { useGLTF, MeshTransmissionMaterial,} from '@react-three/drei'

export function Magrita(props) {
  const { nodes, materials } = useGLTF('/Magrita.glb')
  return (
    <group {...props} dispose={null}>
      
      <mesh geometry={nodes.Margarita_Glass.geometry} 
      material={materials.magritavupbaking} 
      position={[0.001, 0.069, 0]}>

 
       
      

        <group position={[-0.058, 0.102, 0.03]}>
          <mesh geometry={nodes.Circle.geometry} material={materials['Lemon Slice.002']} />
          <mesh geometry={nodes.Circle_1.geometry} material={materials['Lemon.002']} />
        </group>

        <mesh geometry={nodes.Margarita_Drink.geometry} 
        // material={materials.margrita} 
        position={[0, 0.065, 0]} >
           <MeshTransmissionMaterial 
        transmission="1"
        thickness="1"/>
        </mesh>

        <mesh geometry={nodes.Sugar_Rim.geometry} 
        material={materials['Sugar Rim']} 
        position={[0, 0.091, 0]} 
        >
        

        </mesh>
      </mesh>
    </group>
  )
}

useGLTF.preload('/Magrita.glb')
