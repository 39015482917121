/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 Gin-Glasslight.glb 
*/

import React from 'react'
import { useGLTF, MeshTransmissionMaterial, Float } from '@react-three/drei'

export function Gin(props) {
  const { nodes, materials } = useGLTF('/Gin-Glasslight.glb')
  return (
    <group {...props} dispose={null}>

      <mesh geometry={nodes.Gintonica_Glass.geometry} 
      material={materials['Ice Cube']} 
      position={[-0.002, 0.11, 0]} >
     

     <Float
        speed={1} // Animation speed, defaults to 1
        rotationIntensity={0.1} // XYZ rotation intensity, defaults to 1
        floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
        floatingRange={[-0.01,0.02]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
      >
        <mesh geometry={nodes.Bubbles.geometry} 
        material={materials['Ice Cube']} 
        position={[-0.001, 0.04, 0.001]} >

        {/* <MeshTransmissionMaterial 
        transmission="0.99"
        thickness="0.001"/> */}
       
          
        </mesh>
      </Float>

        <mesh geometry={nodes.Gin_Drink001.geometry}  position={[0, 0.05, 0]} >
        <MeshTransmissionMaterial 
        transmission="1"
        thickness="1"/>
        </mesh>
        
        <mesh geometry={nodes.Ice.geometry} material={materials['Ice Cube']} position={[-0.008, 0.064, -0.007]} >
        <MeshTransmissionMaterial 
        transmission="0.95"
        thickness="0.1"/>
        </mesh>

        <group position={[0.038, 0.094, 0.021]}>
          <mesh geometry={nodes.Circle002.geometry} material={materials['Lemon Slice']} />
          <mesh geometry={nodes.Circle002_1.geometry} material={materials.Lemon} />
        </group>

      </mesh>
    </group>
  )
}

useGLTF.preload('/Gin-Glasslight.glb')
