/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 martini2.glb 
*/

import React from 'react'
import { useGLTF, MeshTransmissionMaterial} from '@react-three/drei'

export function Martini(props) {
  const { nodes, materials } = useGLTF('/martini2.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Dry_Martini_Glass.geometry} 
      material={materials.drymartiniCup} 
      position={[-0.194, 0.082, -0.004]} rotation={[0, -0.916, 0]}>

    

        <mesh geometry={nodes.Dry_Martini_Drink.geometry} 
        // material={materials.drymartinidrink} 
        position={[0, 0.059, 0]} >

        <MeshTransmissionMaterial 
        transmission="1"
        thickness="1"/>
        </mesh>


        <group position={[-0.001, 0.046, 0.002]}>
          <mesh geometry={nodes.Sphere001.geometry} material={materials['Olive.002']} />
          <mesh geometry={nodes.Sphere001_1.geometry} material={materials['Olive.003']} />
        </group>
        <mesh geometry={nodes.Stick.geometry} material={materials['Plastic.001']} position={[0.012, 0.102, -0.038]} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/martini2.glb')
